<template>
  <Slide right>
    <nav id="nav-side" class="flex-column">
			<div>
				<ol class="nav flex-column">
					<li class="nav-item">
						<span class="title">
							Aplicaciones
						</span>
					</li>
					<li class="nav-item">
						<router-link :to="{name: 'dashboard'}" class="nav-link"> Inicio </router-link>
					</li>
					<li v-for="app in $auth.getApp()" class="nav-item" data-menu="menu_id_0">
						<router-link :to="{name: $helper.normalizar(app.aplicacion)}" class="nav-link" :data-route="$helper.normalizar(app.aplicacion)">{{ app.aplicacion }}</router-link>
					</li>
				</ol>
				<hr />
			</div>
			<div v-for="(menu_item, index) in menu">
				<ol class="nav flex-column">
					<li v-for="(text, route) in menu_item" class="nav-item" :data-menu="route != 'title' ? 'menu_id_'+(index+1) : 'menu_title'">
						<router-link v-if="route != 'title'" class="nav-link" :to="{name: route}" :data-route="route">{{ text }}</router-link>
						<span v-else class="title">{{ text }}</span>
					</li>
				</ol>
				<hr />
			</div>
      <div>
        <ul class="nav">
	        <li class="nav-item">
	          <router-link class="nav-link" :to="{name: 'my-account'}">Mi cuenta</router-link>
	          <router-link class="nav-link" :to="{name: 'my-account-viaticos'}">Mis viaticos</router-link>
	        </li>
					<li class="nav-item">
	          <span class="nav-link" @click="cerrar_sesion">Cerrar sesión</span>
	        </li>
	      </ul>
      </div>
		</nav>
  </Slide>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { Slide } from 'vue-burger-menu'

export default {

  components: {
    Slide
  },
  
	data: () => ({
		size: null
		,aplicacion: null
	}),

	destroy: function() {
		if (process.env.NODE_ENV !== 'production')
			window.removeEventListener('resize', () => ({}));
	},
	methods: {
      cerrar_sesion: function() {
				this.removeCredentials();
				this.removeMenu();
				this.$router.push({name: 'login'});
			}
			,...mapMutations(['removeCredentials','removeMenu'])
	},
	
	computed: {
		...mapState(['menu'])
	},

	watch: {
		$route: function(newVal, oldVal) {
			this.$log.info('route', newVal.name);

			let encontrado = true;
			let llaves = [];

			this.menu.forEach(menu => {
				llaves = llaves.concat(Object.keys(menu));
			})

			if (llaves.indexOf(newVal.name) == -1) {
				this.$log.info('No encontrado!');
				this.$store.commit('setMenu', []);
			}

			setTimeout(() => {
				document.querySelectorAll('a.nav-link').forEach(link => {
					if (link.getAttribute('data-route') == this.$router.currentRoute.name){
						this.$log.info(link.getAttribute('data-route'), this.$router.currentRoute.name);
						this.$store.commit('setCurrentMenu', this.$router.currentRoute.name);
						link.parentNode.classList.add('link_active');
					}else
						link.parentNode.classList.remove('link_active');
				})
			}, 500);
		}
	}
}
</script>

<style lang="scss">
@media (max-width : 768px) {
	.bm-overlay {
	    background: transparent;
	}

	.bm-menu {
		background-color: #0781cd;
	}
	
	.bm-cross {
		background-color: #fff;
	}

	nav {
	  color: #fff !important;
	  margin-left: 1rem !important;
		
		.title {
			color: #fff;
			font-size: 1.5rem;
		}
		.nav-link {
			color: #fff;
		}
		
		hr {
			border-color: #fff;
		}	   
	}
}
</style>