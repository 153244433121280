<template>
	<div>
		<nav class="navbar navbar-expand-lg">
			<ul class="navbar-nav ml-auto">
				<li v-if="false" class="nav-item dropdown dropleft pt-2">
					<a class="nav-link text-light" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
						Notificaciones
						<span v-if="sin_leer > 0" class="badge badge-light">{{ sin_leer }}</span>
					</a>
					<div class="dropdown-menu px-2">
						<span v-for="notificacion in notificaciones_sin_leer" :key="notificacion.id" :class="'dropdown-item mb-2 border-bottom'+notificacion_titulo_tipo(notificacion)" style="cursor:pointer;" @click="ver_notificacion(notificacion)">{{ notificacion.titulo }}</span>
					</div>
				</li>
				<!-- <li v-else class="nav-item pt-2"><span class="nav-link text-light">Notificaciones</span></li> -->
				<li class="nav-item dropdown dropleft">
					<a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" v-if="windowWidth > 767">
						<img ref="menu_avatar_img" class="main-avatar" src="@/apps/constelacion/assets/img/avatar.svg" alt="Avatar">
					</a>
					<div class="dropdown-menu">
						<router-link class="dropdown-item" :to="{name: 'my-account'}">Mi cuenta</router-link>
						<router-link v-if="$auth.getAuth().usuario.organizacion_id == 1" class="dropdown-item" :to="{name: 'my-account-viaticos'}">Mis viaticos</router-link>
						<router-link v-if="$auth.getAuth().usuario.organizacion_id == 1" class="dropdown-item" :to="{name: 'my-account-tickets'}">Mis tickets</router-link>
						<span class="dropdown-item" @click="cerrar_sesion">Cerrar sesión</span>
					</div>
				</li>
			</ul>
		</nav>

		<Modal v-if="notificacion.titulo" :options="{type: notificacion.tipo}" @close="cerrar_notificacion">
			<div class="title">
				Notificación - {{ notificacion.titulo }}
			</div>
			<div class="body">
				<div class="row">
					<div class="col-sm-10"><strong>Remitenten:</strong> {{ notificacion.de.nombre }}</div>
					<div class="col-sm-2">{{ $moment(notificacion.created_at).format('H:mm DD-MM-YYYY') }}</div>
				</div>
				<div v-html="notificacion.cuerpo"></div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn" @click="cerrar_notificacion">Cerrar</button></div>
					<div class="col-sm-2"><button class="btn danger-btn" @click="eliminar_notificacion">Eliminar</button></div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import avatar from '@/apps/constelacion/assets/svg/Avatar'
import Modal from '@/components/Modal'

import api from '@/api/account'

export default {
	props: {
		windowWidth: {
			default: 0,
			type: Number
		}
	},

	components: {
		avatar,Modal
	},
	data: function() {
		return {
			notificaciones: []
			,sin_leer: 0
			,notificacion: {
				de: null
				,titulo: null
				,cuerpo: null
			}
			,timeoutId: null
		}
	},
	created: function() {
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			// this.$log.info('mutation', mutation.type);
			if (mutation.type == 'avatarUpdateStatus') {

				if (state.avatar == 'change') {
					this.actualizar_avatar();
					state.avatar = 'ready';
				}
			}
		})
	},
	beforeDestroy: function() {
		this.unsubscribe();
	},
	mounted() {
		// this.$log.info(this.windowWidth)
		this.obtener_avatar();
		// this.obtener_notificaciones(true);
	}
	,methods: {
		getMenu: function(menu) {
			this.menu = menu;
		}
		,showMenu: function() {
			let  nav = document.getElementById('nav-side');

			if (nav.style.display == 'none') {
				nav.style.display = 'block';
				// nav.parentNode.style.marginTop = '40px';
				nav.parentNode.style.height = 'auto';
			}else {
				nav.style.display = 'none';
				nav.parentNode.style.height = '0px';
			}
		}
		,cerrar_sesion: function() {
			this.removeCredentials();
			this.removeMenu();
			this.$router.push({name: 'login'});
		}
		,mostrar_menu: function(menu) {
			let id_menu = 'menu_id_'+menu;

			document.querySelectorAll('li[data-menu='+id_menu+']').forEach(item => {
				this.$log.info('display: ',item.style.display);
				if (item.style.display == 'list-item')
					item.style.display = 'none';
				else
					item.style.display = 'list-item';
			})
		}
		,obtener_avatar: async function() {
			try {
				let avatar = (await api.cargar_avatar(this.$auth.getUser().id)).data;

				let img = this.$refs.menu_avatar_img;
				img.src = 'data:'+avatar.type+';base64,'+avatar.data;
			}catch(e) {
				this.$log.info('err',e);
			}
		}
		,actualizar_avatar: function() {
			this.$log.info('Avatar cambio!');

			this.obtener_avatar();
		}
		,obtener_notificaciones: async function(first) {
			try {
				this.notificaciones = (await api.obtener_notificaciones()).data;

				let sin_leer = 0;
				this.notificaciones.forEach(not => {
					// this.$log.info('leido', not.leido_at);
					if (!not.leido_at )
						sin_leer++;
				});

				if (this.sin_leer < sin_leer)
					this.$helper.showMessage('Nuevas notificaciones','Tienes '+sin_leer+' nuevas notificaciones','info');

				this.sin_leer = sin_leer;

				let parent = this;

				// this.timeoutId = setTimeout(() => {
				// 	parent.obtener_notificaciones();
				// },5000);
			}catch(e) {
				this.$log.info('err',e);
				this.$helper.showAxiosError(e,'Error');
			}
		}
		,notificacion_titulo_tipo: function(notificacion) {
			switch(notificacion.tipo) {
				case 'primary':
					return ' text-primary';// bg-primary';
					break;
				case 'secondary':
					return ' text-secondary';// bg-secondary';
					break;
				case 'success':
					return ' text-success';// bg-success';
					break;
				case 'danger':
					return ' text-danger';// bg-danger';
					break;
				case 'error':
					return ' text-danger';// bg-danger';
					break;
				case 'warning':
					return ' text-warning';
					break;
				// case 'info':
				// 	return ' text-light';// bg-info';
				// 	break;
			}
		}
		,ver_notificacion: async function(notificacion) {
			this.notificacion = notificacion;

			await api.leer_notificacion(notificacion.id);

			clearTimeout(this.timeoutId);

			this.obtener_notificaciones();
		}
		,cerrar_notificacion: function() {
			this.notificacion = {
				de: null
				,titulo: null
				,cuerpo: null
			}
		}
		,eliminar_notificacion: async function() {
			try {
				await api.eliminar_notificacion(this.notificacion.id);

				this.cerrar_notificacion();

				clearTimeout(this.timeoutId);

				this.obtener_notificaciones();
			}catch(e) {
				this.$log.info('err',e);
				this.$helper.showAxiosError(e, 'Error');
			}
		}
		,...mapMutations(['removeCredentials','removeMenu'])
	}
	,computed: {
		...mapState(['menu'])
		,notificaciones_sin_leer: function() {
			let notificaciones = [];

			this.notificaciones.forEach(notificacion => {
				if (notificacion.leido_at == null)
					notificaciones.push(notificacion);
			})

			return notificaciones;
		}
	}
	,watch: {

	}
}
</script>

<style lang="scss">
	.avatar {
		border-radius: 50%;
	}
</style>