<template>
	<div class="constelacion">
		<Aside :windowWidth="windowWidth" v-if="windowWidth > 767" />
		<main>
			<header>
				<div class="logo d-block d-sm-none">
					<router-link :to="{name: 'dashboard'}" >
						<img src="https://static.bienparabien.com/constelacion/img/constelacion-logoblanco.svg" alt="Bien para Bien" height="auto" width="55px">
					</router-link>
				</div>
				<Main :windowWidth="windowWidth"/>
				<MenuMobile v-if="windowWidth < 767"/>
			</header>
			
			<div class="content">
		  		<router-view/>
		  	</div>
		</main> 

		<Modal v-if="mostrarError" :options="{width: '40vw', type: 'error', close: true}" @close="mostrarError=false">
			<div class="title">Notificaciones rechazadas</div>
			<div class="body">
				<div class="row">
					<div class="col-sm-12 text-center">Haz rechazado las notificaciones por lo cual solo te llegarán las notificaciones por correo</div>
				</div>
				<div class="row mt-3">
					<div class="col-sm-2 offset-sm-4">
						<button class="btn principal-btn mr-2" @click="agregarNotificaciones">Activar</button>
					</div>
					<div class="col-sm-2">
						<button class="btn complementary-btn" @click="mostrarError=false">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import { mapState, mapMutations } from 'vuex'
	import Aside from './Aside.vue'
	import Main from './Main.vue'
	import MenuMobile from "./MenuMobile"
	// import firebaseMssg from '@/plugins/firebase'
	import account from '@/api/account'
	import Modal from '@/components/Modal'

	export default {
		components: {
			Aside,
			Main,
			MenuMobile,
			Modal
		},

		data() {
			return {
				windowWidth: window.innerWidth,
				mostrarError: false
			}
		},

		created() {
			this.windowWidth = window.innerWidth;
			window.addEventListener('resize', () => {
				this.windowWidth = window.innerWidth;
				// if(this.windowWidth < 767){
				// 	this.mobilestate = null
				// }
			})
		},

		mounted: function() {
			// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

			// if (!isSafari && process.env.VUE_APP_ENABLE_PUSH_NOTIFICATION) {
			// 	navigator.serviceWorker.register('/static/firebase-messaging-sw.js')
			// 	.then(registration => {
			// 		firebaseMssg.useServiceWorker(registration);

			// 		firebaseMssg.requestPermission()
			// 		.then(() => {
			// 			firebaseMssg.getToken()
			// 			.then(async (token) => {
			// 				this.$store.commit('set', {firebase:{token: token}})

			// 				await account.registrar_dispositivo({usuario_id: this.$auth.getUser().id, token: token});
			// 			})
			// 			.catch(err => {
			// 				this.$log.info('err3', err);
			// 			})
			// 		})
			// 		.catch(err => {
			// 			this.$log.info('err2', err);
			// 			this.mostrarError = true;
			// 		})
			// 	})
			// 	.catch(err => {
			// 		this.$log.info('err1', err);
			// 	})

			// 	firebaseMssg.onMessage(payload => {
			// 		this.$log.info('notificacion', payload);

			// 		let options = JSON.parse(payload.data.options);

			// 		this.$notify({
			// 			group: (options.group ? options.group : 'notifications')
			// 			,type: (options.type ? options.type : 'success')
			// 			,title: payload.notification.title
			// 			,text: payload.notification.body
			// 			// ,duration: 5000
			// 		});
			// 	})
			// }
		}
		,methods: {
			agregarNotificaciones: function() {
				firebaseMssg.requestPermission()
				.then(() => {
					firebaseMssg.getToken()
					.then(async (token) => {
						this.$store.commit('set', {firebase:{token: token}});

						this.mostrarError = false;

						await account.registrar_dispositivo({usuario_id: this.$auth.getUser().id, token: token});
					})
					.catch(err => {
						this.$log.info('err3', err);
						this.$helper.showMessage('Error','Error al activar notificaciones','error','alert');
					})
				})
				.catch(err => {
					this.$log.info('err2', err);
					this.mostrarError = true;
				})

				firebaseMssg.onMessage(payload => {
					this.$log.info('notificacion', payload);

					let options = JSON.parse(payload.data.options);

					this.$notify({
						group: (options.group ? options.group : 'notifications')
						,type: (options.type ? options.type : 'success')
						,title: payload.notification.title
						,text: payload.notification.body
						// ,duration: 5000
					});
				})
			}
		}
	}
</script>

<style lang="scss">
	.constelacion {
		width: 100vw;
		min-height: 100vh;
		background-color: #fff !important;
		// font-size: 12px !important;
		margin: 0px;
		padding: 0px;
		display: flex;
		flex-direction: row;
		overflow: auto;

		aside {
			background-color: #1561d3;
			width: auto;
			z-index: 900;

			.logo {
				text-align: center;
				margin: 20px 0px;

				img {
					max-width: 150px;
					height: auto;
				}
			}

			ol.nav {
				margin-bottom: 30px;
				padding: 0px 15px;

				li {
					border-bottom: solid 1px #0e2773;
					display: none;

					a {
						color: #CCCCCC;
					}

					a:hover {
						color: #fff;
					}

					a.active {
						color: #fff;
					}

					span.title {
						// text-align: center;
						font-size: 1.5em;
						font-weight: bold;
						white-space: nowrap;
						color: #fff;
						// padding-left: 15px;
					}

					ol.navbar-submenu {
						list-style: none;
						padding-left: 0px;

						li {
							span.subtitle {
								// text-align: center;
								// font-size: 1.1em;
								font-weight: bold;
								white-space: nowrap;
								color: #fff;
							}
						}

						li:first-child {
							padding: 0.5rem 1rem !important;
						}

						li:last-child {
							border-bottom: 0px;
						}
					}
				}

				li.link_active {
					
					a {
						background-color: #0E2773;
						color: #fff;
					}
				}

				li:first-child {
					display: list-item;
				}
			}

			ol.nav:first-child {
				li {
					display: list-item;
				}
			}

			ol:last-child {
				margin-bottom: 0px ;
			}
		}

		main {
			flex: 1 1 auto;

			header {
				background-color: #1561d3;
				// padding: .5rem 1rem;

				.notificaciones {
					width: 40px;
					height: 40px;
					vertical-align: middle;
					margin-top: 10px;
				}

				.main-avatar {
					width: 40px;
					height: 40px;
					border-radius: 50%;
				}

				.btn-burger {
					display: none;
				}
			}

			.content {
				padding: 0px 15px !important;
			}
		}
	}

	@media (max-width: 768px) {
		.constelacion {
			display: inline;

			aside {
				width: 100%;
				// height: 0px;
				margin-top: 72px;
				position: relative;
				
				.logo {
					text-align: left;
					position: absolute;
					top: -82px;
					left: 0px;
					z-index: 100;

					img {
						width: 60px;
						margin-top: -18px;
    				margin-left: 1rem;					
					}
				}
			}

			main {
				header {
					padding: .5rem;
					width: 100%;
					height: 80px;

					.bm-burger-button {
						top: 25px !important;
						right: 30px !important;

						.bm-burger-bars {
							background-color: #fff;
						}

						.line-style {
							height: 10%;
						}
					}
				}
			}
		}
	}
</style>