<template>
    <aside>
			<div class="logo">
				<router-link :to="{name: 'dashboard'}" >
					<img src="https://static.bienparabien.com/constelacion/img/constelacion-logoblanco.png" alt="Bien para Bien">
				</router-link>
			</div>
			<nav id="nav-side">
				<ol class="nav flex-column navbar-menu" data-menu="0">
					<li class="nav-item" data-menu="menu_title">
						<span class="title" @click="mostrar_menu(0)">
							Aplicaciones <img src="@/apps/constelacion/assets/img/arrow-down.svg" alt="down">
						</span>
					</li>
					<li class="nav-item">
						<router-link :to="{name: 'dashboard'}" class="nav-link"> Inicio </router-link>
					</li>
					<li v-for="app in $auth.getApp()" class="nav-item" data-menu="menu_id_0" :key="app.id">
						<router-link :to="{name: $helper.normalizar(app.aplicacion)}" class="nav-link" :data-route="$helper.normalizar(app.aplicacion)">{{ app.aplicacion }}</router-link>
					</li>
				</ol>

				<ol v-for="(menu_item, index) in menu" :key="index" class="nav flex-column navbar-menu" :data-menu="(index+1)">
					<li v-for="(text, route) in menu_item" class="nav-item" :key="route" :data-menu="route != 'title' ? 'menu_id_'+(index+1) : 'menu_title'">
						<template v-if="typeof text == 'string'">
							<router-link v-if="route != 'title'" class="nav-link" :to="{name: route}" :data-route="route">{{ text }}</router-link>
							<span v-else class="title" @click="mostrar_menu((index+1))">{{ text }}  <img src="@/apps/constelacion/assets/img/arrow-down.svg" alt="down"></span>
						</template>
						<template v-else-if="Array.isArray(text)">
							<router-link v-if="route != 'title'" class="nav-link" :to="{name: route, params: text[1]}" :data-route="route">{{ text[0] }}</router-link>
							<span v-else class="subtitle" @click="mostrar_menu((index+1))">{{ text }}</span>
						</template>
						<template v-else>
							<ol class="nav flex-column navbar-submenu">
								<li v-for="(subtext, subroute) in text" :key="subtext" class="nav-item">
									<router-link v-if="subroute != 'title'" class="nav-link" :to="{name: subroute}" :data-route="subroute">{{ subtext }}</router-link>
									<span v-else class="subtitle" @click="mostrar_menu((index+1))">{{ subtext }}</span>
								</li>
							</ol>
						</template>
					</li>
				</ol>
			</nav>
		</aside> 
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {

	data: () => ({
		size: null
		,aplicacion: null
		,items_menu: []
	}),
		
	created: function() {
		if (process.env.NODE_ENV !== 'production') {
			window.addEventListener('resize', () => {
				let size = window.innerWidth;
				let nav = document.getElementById('nav-side');

				if (size > 768) {
					nav.style.display = 'block';
					nav.parentNode.style.height = 'auto';
				}else {
					nav.style.display = 'none';
					nav.parentNode.style.height = '0px';
				}
			});
		}
	}
	,mounted: function() {
		this.detectar_ruta();

		this.mostrar_menu();
	}
	,destroy: function() {
		if (process.env.NODE_ENV !== 'production')
			window.removeEventListener('resize', () => ({}));
	},
	methods: {
		mostrar_menu: function(id) {
			let menus = document.querySelectorAll('.navbar-menu');
			if (typeof(id) == 'undefined')
				id = menus.length-1;

			if (menus.length > 1) {
				menus.forEach(menu => {
					if (parseInt(menu.getAttribute('data-menu')) == id) {
						// this.$log.info('Menu activo',parseInt(menu.getAttribute('data-menu')));
						menu.childNodes.forEach(link => {
							// this.$log.info('tipo', link.getAttribute('data-menu'));
							if (link.getAttribute('data-menu') != 'menu_title')
								link.style.display = 'list-item';
						})
					}else {
						// this.$log.info('Menu inactivo', parseInt(menu.getAttribute('data-menu')));
						menu.childNodes.forEach(link => {
							// this.$log.info('tipo', link.getAttribute('data-menu'));
							if (link.getAttribute('data-menu') != 'menu_title')
								link.style.display = 'none';
						})
					}
				})
			}
		}
		,detectar_ruta: function() {
			// setTimeout(() => {
				document.querySelectorAll('a.nav-link').forEach(link => {
					if (link.getAttribute('data-route') == this.$router.currentRoute.name){
						// this.$log.info(link.getAttribute('data-route'), this.$router.currentRoute.name);
						this.$store.commit('setCurrentMenu', this.$router.currentRoute.name);
						link.parentNode.classList.add('link_active');
					}else
						link.parentNode.classList.remove('link_active');
				})
			// }, 500);
		}
	},
	
	computed: {
		...mapState(['menu'])
	},

	watch: {
		menu: function(newVal, oldVal) {
			// this.$log.info('menu', newVal);
			if (newVal.length > 0) {
				setTimeout(() => {
					this.mostrar_menu(newVal.length);
				},300);
			}else
				this.mostrar_menu(0);
		}
		,$route: function(newVal, oldVal) {
			// this.$log.info('route', newVal.name);

			// setTimeout(() => {
				let encontrado = true;
				let llaves = [];

				if (this.menu) {
					this.menu.forEach(menu => {
						llaves = llaves.concat(Object.keys(menu));
					})

					if (llaves.indexOf(newVal.name) == -1) {
						this.$log.info('No encontrado!');
						// this.$store.commit('setMenu', []);
					}
				}

				this.detectar_ruta();
			// }, 500);
		}
	}
}
</script>